<template>
  <div class="main">
      <div class="topDiv">
        <p class="nameClass">米果妈妈国风馆</p>
        <div class="btnDiv">
            <div>
                <p>您的使用周期至：</p>
                <p>2022年3月24日</p>
            </div>
            <div>
                <p @click="jumpBtn(0)">我要续订</p>
                <p @click="jumpBtn(1)">进入软件</p>
            </div>
        </div>
      </div>
      <div class="contentDiv">
            <div class="divClass">
                <p class="titleClass">订购记录：</p>
                <el-table
                    :data="tableList"
                    width='100%'
                    header-row-class-name='headerClass'
                    row-class-name='tdClass'
                    >
                    <el-table-column
                        v-for="(item, index) in tableHeader"
                        :key="index"
                        :label="item.name"
                        :min-width='item.width'
                        :prop="item.key">
                    </el-table-column>
                </el-table>
            </div>
      </div>
  </div>
</template>

<script>
export default {
  name: "buyRecord",
  data() {
    return {
        tableHeader: [
            {name: '订单编号', key:'name', width:'25%'},
            {name: '操作时间', key:'name', width:'15%'},
            {name: '订购日期', key:'name', width:'15%'},
            {name: '订购版本', key:'name', width:'10%'},
            {name: '有效期', key:'name', width:'10%'},
            {name: '应付金额', key:'name', width:'10%'},
            {name: '付款状态', key:'type', width:'10%'},
            {name: '备注', key:'name', width:'10%'},
        ],
        tableList:[
            {name: '123', type:'0'},
            {name: '123', type:'0'},
        ]
    };
  },
  methods: {
      jumpBtn(num) {
          if(num == '0') {
              window.open('https://fxg.jinritemai.com/ffa/mopen/serviceAuthorizeManage?page=1&size=20&tab=1')
          }
      }
  },
};
</script>

<style lang="less" scoped>
.main {
    width: 100%;
    height: 100%;
    background-color: #f2f0f0;
    display: flex;
    align-items: center;
    flex-direction: column;
}
.topDiv {
    width: 80%;
    min-height: 150px;
    background-color: #fff;
    border: 1px solid #d9d9d9;
    margin-top: 20px;
    font-size: 14px;
    line-height: 14px;
}
.nameClass {
    color: #58b458;
    font-size: 25px;
    line-height: 25px;
    margin-top: 35px;
    margin-left: 30px;
}
.btnDiv {
    padding: 0 20px 0 30px;
    display: flex;
    justify-content: space-between;
    div:nth-child(1) {
        color: #365064;
        margin-top: 20px;
        p:nth-child(2) {
            font-size: 20px;
            line-height: 20px;
            color: #58b458;
            margin-top: 10px;
        }
    }
    div:nth-child(2) {
        color: #fff;
        p {
            font-size: 14px;
            line-height: 14px;
            padding: 10px 25px;
            background-color: #00ccca;
            border-radius: 3px;
            cursor: pointer;
        }
        p:nth-child(2) {
            margin-top: 10px;
            background-color: #6ebb00;
        }
    }
}
.contentDiv {
    width: 80%;
    background-color: #fff;
    border: 1px solid #d9d9d9;
    margin-top: 30px;
    padding-bottom: 20px;
    .divClass {
        width: 92%;
        margin: 0 auto;
    }
    .titleClass {
        margin: 30px 0 10px 0;
        color: #365064;
    }
}
/deep/ .headerClass th {
    background-color: #e1e8ed;
    text-align: center;
    color: #365064;
    border: none;
}
/deep/ .tdClass td {
    border: none;
    border-bottom: 1px dashed #eaeaea;
}
/deep/ .tdClass td div {
    display: flex;
    justify-content: center;
}
.el-table::before {
    height: 0px;
}
</style>